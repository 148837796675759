<template>
  <div class="custom-frame" :class="backgroundStyle">
    <div class="title">
      {{ title }}
      <img class="go-back-image" @click="$router.go(-1)" src="@/assets/test/go-back.png"/>
    </div>
    <div class="box" :style="heightNumber"></div>
  </div>
</template>

<script>
  export default {
    name: "CustomHead",
    props: {
      title: {
        type: String,
        default: ''
      },
      height:{
        type: Number,
        default: 550
      },
      testType:{
        type: Number,
        default: 4
      }
    },
    computed:{
      heightNumber(){
        return "height:" + this.height + 'px'
      },
      backgroundStyle(){
        let className
        if(this.testType === 4){
          className = 'custom-frame-4'
        }else if(this.testType === 5){
          className = 'custom-frame-5'
        }
        return className
      }
    }
  }
</script>

<style scoped lang="less">
  @media screen and (max-width: 1024px) {

  }

  .custom-frame {
    padding: 1px;
    max-width: 750px;
    margin: 0 auto;
    min-height: 100vh;
    .title {
      padding: 30px 0 20px;
      text-align: center;
      font-weight: 700;
      color: #ffffff;
      font-size: 1.2rem;
      position: relative;
      .go-back-image {
        width: 10px;
        /*height: 20px;*/
        position: absolute;
        left: 20px;
        bottom: 22px;
        cursor: pointer;
      }
    }
    .box {
      margin: 30px 20px;
      padding: 20px;
      background: linear-gradient(to bottom, #E9FDFF, #FFFAEB);
      border: 5px solid rgba(var(--themecolor),0.5);
      border-radius: 30px;
      box-shadow: 10px 10px 15px 0 rgba(87, 113, 173, 0.2);
    }
  }
  .custom-frame-4{
    background: url("../../../assets/test/head-background.png") no-repeat top right/100%, linear-gradient(to bottom, rgba(var(--themecolor),0), rgba(var(--themecolor),1));
  }
  .custom-frame-5{
    background: url("../../../assets/test/head-background.png") no-repeat top right/100%, linear-gradient(to bottom, #F4D25E, rgba(var(--themecolor),0.75));
  }
</style>
