<template>
  <div class="main-box">
    <div class="test-content">
      <custom-frame title="新高考选科测评"></custom-frame>
      <div class="title">{{ titleStatus }}</div>
      <div v-if="submitShow" id="test_content" class="main">
        <div v-if="mainData.length !== 0" class="box">
          <div class="question">{{ mainData[indexNumber].Question }}</div>
          <div class="item" :class="selectedStatus(item)" @click="addAnswer(item)"
               v-for="(item, index) in mainData[indexNumber].AnswerList" :key="index">
            {{ item.AnswerItem }}
          </div>
        </div>
      </div>
      <div v-else class="bottom">
        <div class="text">
          <div>已完成所有测评</div>
          <div>将平时成绩排序后即可查看您的测评分析</div>
        </div>
        <div class="submit-button" @click="toSortScore">成绩排序</div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {SelSubjectQuestionList} from '@/api/test/test'
  import CustomFrame from '@/views/test/custom-components/CustomFrame'

  export default {
    components: {
      CustomFrame
    },
    name: "test-content",
    data () {
      return {
        flag: true,
        mainData: [],
        indexNumber: 0,
        answerList: [],
      }
    },
    computed: {
      //提交按钮展示状态
      submitShow () {
        return this.indexNumber < this.mainData.length && this.mainData.length !== 0
      },
      titleStatus () {
        const tempLength = this.mainData.length
        return this.indexNumber >= tempLength ? tempLength + ' / ' + tempLength : this.indexNumber + 1 + ' / ' + tempLength
      },
      heightNumber () {
        this.$nextTick(() => {
          return document.getElementById('test_content').offsetHeight
        })
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //sort subject
      toSortScore () {
        this.$ls.set('test-newExamChooseAnswer', this.answerList)
        this.$router.push({
          path: '/exam-test/sort-score',
          query: {
            testType: 4
          }
        })
      },
      //select answer
      addAnswer (item) {
        if (this.flag) {
          this.flag = false
          this.answerList.push(item.AnswerId)
          setTimeout(() => {
            this.indexNumber++
            this.flag = true
          }, 300)
        }
      },
      // initialize data
      initialize () {
        this.getQuestionList()
      },
      // 选中状态
      selectedStatus (item) {
        return this.answerList.includes(item.AnswerId) ? 'activate-answer' : ''
      },
      // 获取题库
      getQuestionList () {
        API.Career.SelSubjectQuestionList({
          ProvinceId: this.$ls.get('test-newExamChoose')
        }).then(listData => {
          this.mainData = listData.data
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .main-box{
    width: 100%;
    background: linear-gradient(to bottom, rgba(var(--themecolor),0.75), rgba(var(--themecolor),1));
  }
  .test-content {
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    border: 1px solid #eeefff;
    .title {
      width: 100%;
      padding: 20px 40px;
      color: rgba(var(--themecolor),0.75);
      text-align: center;
      position: absolute;
      font-size: 20px;
      font-weight: bold;
      top: 120px;
      left: 0;
    }
    .main {
      box-sizing: border-box;
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 120px;
      left: 0;
      .box {
        .question {
          line-height: 1.5;
          padding: 20px;
          font-size: 1rem;
        }
        .item {
          padding: 10px 20px;
          margin-bottom: 20px;
          background-color: rgba(var(--themecolor),0.15);
          border-radius: 20px;
          font-size: 0.92rem;
          cursor: pointer;
        }
        .activate-answer {
          background: linear-gradient(to right, rgba(var(--themecolor),0.55), rgba(var(--themecolor),0.35));
          color: #ffffff;
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 40px;
      position: absolute;
      top: 180px;
      left: 0;
      box-sizing: border-box;
      .text {
        line-height: 1.5;
        padding: 40px;
        text-align: center;
        color: #515357;
        font-weight: 700;
      }
      .submit-button {
        width: 150px;
        padding: 10px 20px;
        margin: 40px auto 0;
        text-align: center;
        background-color: rgba(var(--themecolor),0.75);
        color: #ffffff;
        border-radius: 20px;
        cursor: pointer;
      }
      .submit-button:hover{
        background-color: rgba(var(--themecolor),1);
      }
    }
  }

</style>
